"use client";

import { RootErrorBoundary } from "@/components/section/error-boundary/root-error-boundary";

function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return <RootErrorBoundary error={error} resetErrorBoundary={reset} />;
}

export default Error;
